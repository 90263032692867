import { ModalBase } from 'components';
import { AiModelMetricsTable, metricsTableTestIds } from 'pages/aiPlatform/components';
import {
  TestingContentHeader,
  testIds as tableHeaderTestIds,
} from 'pages/aiPlatform/TrainingPipelinePage/components/TestingContent/TestingContentHeader';
import { TestingStepCompleted } from 'stores/aiPlatform/aiModels';

interface EvaluationResultsModalProps {
  opened: boolean;
  testingStep: TestingStepCompleted;
  onClose: () => void;
}

export const testIds = {
  modal: 'evaluation-results-modal',
  table: metricsTableTestIds,
  tableHeader: tableHeaderTestIds,
};

export const EvaluationResultsModal = ({ opened, testingStep, onClose }: EvaluationResultsModalProps) => (
  <ModalBase
    opened={opened}
    title=''
    size='auto'
    centered
    headerProps={{ mb: 0 }}
    bodyProps={{ px: 'lg', className: 'flex flex-col min-h-0' }}
    contentProps={{ className: 'flex flex-col' }}
    onClose={onClose}
    data-testid={testIds.modal}
  >
    <TestingContentHeader
      pt={0}
      failedSampleCount={testingStep.failedSamplesCount}
      failedSamplesLink={testingStep.failedSamplesLink}
    />
    <AiModelMetricsTable
      pages={testingStep.results}
      classNames={{
        paper: 'flex flex-col',
      }}
      withFooter={false}
    />
  </ModalBase>
);
