import { FlowMetadata, FlowMetadataField, FlowMetadataFieldType } from '@jargonic/flow-metadata-types';
import { Flow } from 'stores/flows';
import { nanoid } from 'nanoid';
import i18n from 'services/i18n';
import { FieldValuesCSVParsedPayload, FlowSettingsData, MetadataType } from './flowSettings.types';

export function dataFromApi(flow: Flow, metadata: FlowMetadata): FlowSettingsData {
  return {
    flow: {
      dateFormat: flow.dateFormat,
      maxInspectors: flow.maxInspectors,
      name: flow.name,
      description: flow.description,
      singleInstance: flow.singleInstance,
      supportedCommonEvents: flow.supportedCommonEvents,
      expiration: flow.expiration,
      voiceInputEnabled: flow.voiceInputEnabled,
    },
    metadata: {
      title: metadata.title,
      postInspectionFields: metadata.postInspectionFields || [],
      preInspectionFields: metadata.preInspectionFields || [],
      uniqueIdentifierFieldId: metadata.uniqueIdentifierFieldId || '',
    },
  };
}

export function fieldsKey(type: MetadataType): 'preInspectionFields' | 'postInspectionFields' {
  return type === 'pre' ? 'preInspectionFields' : 'postInspectionFields';
}

export function getDefaultField(order: number): FlowMetadataField {
  return {
    id: nanoid(10),
    title: i18n.t('wizard.steps.settings.metadata.enumeratedFieldName', { n: order + 1 }),
    type: FlowMetadataFieldType.String,
    required: false,
    exposed: false,
    order,
  };
}

export function getDefaultMetadata(id: string): FlowMetadata {
  return {
    id,
    title: i18n.t('wizard.steps.settings.preFlow.metadataTitle.default'),
    preInspectionFields: [],
    postInspectionFields: [],
  };
}

export function getFieldValuesFromCSVPayload(payload: FieldValuesCSVParsedPayload[]): string[] {
  const values = payload.filter(Boolean).map(({ value }) => value.toLowerCase());
  return Array.from(new Set(values));
}
