import i18n from 'services/i18n';
import { MetricCell } from './cells/MetricCell';

export const columns = [
  {
    accessorKey: 'label',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.type'),
    grow: 1,
  },
  {
    accessorKey: 'metrics.precision',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.precision'),
    Cell: MetricCell,
    grow: 0,
  },
  {
    accessorKey: 'metrics.recall',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.recall'),
    Cell: MetricCell,
    grow: 0,
  },
  {
    accessorKey: 'metrics.f1_score',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.f1'),
    Cell: MetricCell,
    grow: 0,
  },
  {
    accessorKey: 'metrics.support',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.support'),
    grow: 0,
  },
];

export const trainingStepMetricsNlp = [
  {
    accessorKey: 'label',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.type'),
    grow: 1,
  },
  {
    accessorKey: 'epoch',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.epoch'),
    grow: 0,
  },
  {
    accessorKey: 'metrics.precision',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.precision'),
    Cell: MetricCell,
    grow: 0,
  },
  {
    accessorKey: 'metrics.recall',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.recall'),
    Cell: MetricCell,
    grow: 0,
  },
  {
    accessorKey: 'metrics.f1',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.f1'),
    Cell: MetricCell,
    grow: 0,
  },
  {
    accessorKey: 'metrics.support',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.support'),
    grow: 0,
  },
];
export const trainingStepMetricsAsr = [
  {
    accessorKey: 'label',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.type'),
    grow: 1,
  },
  {
    accessorKey: 'iteration',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.iteration'),
    grow: 0,
  },
  {
    accessorKey: 'value',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.value'),
    Cell: MetricCell,
    grow: 0,
  },
];
export const evaluationStepMetricsAsr = [
  {
    accessorKey: 'label',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.type'),
    grow: 1,
  },
  {
    accessorKey: 'value',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.value'),
    Cell: MetricCell,
    grow: 0,
  },
];
