import { HTMLPropsRef, MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table';
import React, { useState } from 'react';
import { IterationResults, LabelMetrics } from 'stores/aiPlatform/aiModels';
import { columns as defaultColumns } from './AiModelMetricsTable.columns';
import { initialPagination, metricsTableDefs, rowPinning } from './AiModelMetricsTable.defs';

interface AiModelMetricsTableProps {
  pages: IterationResults[];
  header?: React.ReactNode;
  withFooter?: boolean;
  classNames?: {
    table?: string;
    paper?: string;
  };
  columns?: MRT_ColumnDef<LabelMetrics, unknown>[];
}

export const testIds = {
  table: 'metrics-table',
  pagination: 'metrics-pagination',
  getRowTestId: (id: string) => `metrics-table-row-${id}`,
};

export const AiModelMetricsTable = ({
  pages,
  header,
  classNames,
  columns,
  withFooter = true,
}: AiModelMetricsTableProps) => {
  const [pagination, setPagination] = useState(initialPagination);

  const data = pages[pagination.pageIndex]?.labels ?? [];

  const table = useMantineReactTable({
    // @ts-expect-error
    data,
    columns: columns ?? defaultColumns,
    ...metricsTableDefs,
    enableBottomToolbar: withFooter,
    enableTopToolbar: !!header,
    renderTopToolbar: header,
    mantineTableContainerProps: { className: classNames?.table },
    mantinePaginationProps: () => ({
      showRowsPerPage: false,
      total: pages.length,
      'data-testid': testIds.pagination,
    }),
    mantineTableBodyRowProps: ({ row }) => ({
      bg: row.getIsPinned() ? 'gray.1' : '',
      'data-testid': testIds.getRowTestId(row.id),
    }),
    mantineTableProps: () => ({ 'data-testid': testIds.table }) as HTMLPropsRef<HTMLTableElement>,
    mantinePaperProps: { className: classNames?.paper },
    onPaginationChange: setPagination,
    state: { pagination },
    initialState: { rowPinning: data.length > 0 ? rowPinning : {} },
  });

  return <MantineReactTable table={table} />;
};
