import { useState, useEffect, RefObject } from 'react';

/**
 * Custom hook to determine if the content of an element is truncated (overflowing).
 * @param ref - A ref object pointing to the element to check for truncation.
 * @returns `true` if the content is truncated, `false` otherwise.
 */
export const useIsTruncated = (ref: RefObject<HTMLElement>) => {
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      setIsTruncated(element.scrollWidth > element.clientWidth);
    }
  }, [ref]);

  return isTruncated;
};
