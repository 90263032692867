import { Checkbox, Divider, Flex, NumberInput, Select, Stack, Textarea, TextInput } from '@mantine/core';
import { EditableFlowValues, useFlowSettingStore } from 'stores/wizard';
import { useTranslation } from 'react-i18next';
import { InfoLabel } from 'components';
import { dateFormatOptions } from './GeneralSettings.const';

export const testIds = {
  wrapper: 'flow-settings-general-settings-wrapper',
  name: 'flow-settings-general-settings-name',
  description: 'flow-settings-general-settings-description',
  voiceCapabilities: {
    input: 'general-settings-voice-capabilities-input',
    label: 'general-settings-voice-capabilities-label',
  },
  userLimit: {
    input: 'general-settings-users-input',
    label: 'general-settings-users-label',
    capInput: 'general-settings-users-cap-input',
  },
  instanceLimit: {
    input: 'general-settings-instance-input',
    label: 'general-settings-instance-label',
  },
  dateFormat: { input: 'general-settings-date-format-input', label: 'general-settings-date-format-label' },
};

export const GeneralSettings = () => {
  const { t } = useTranslation();
  const { data, updateGeneralSettings: update } = useFlowSettingStore(['data', 'updateGeneralSettings']);
  const values = data.flow;

  return (
    <Flex gap='xl' data-testid={testIds.wrapper}>
      <Stack miw={400}>
        <TextInput
          maw={400}
          label={t('wizard.steps.settings.generalSettings.flowName.title')}
          value={values.name}
          required
          onChange={(e) => update({ name: e.target.value })}
          data-testid={testIds.name}
        />
        <Textarea
          label={t('wizard.steps.settings.generalSettings.flowDescription.title')}
          placeholder={t('wizard.steps.settings.generalSettings.flowDescription.placeholder')}
          value={values.description}
          onChange={(e) => update({ description: e.target.value })}
          data-testid={testIds.description}
        />
      </Stack>
      <Divider orientation='vertical' />
      <Stack gap='md'>
        <Select
          value={values.dateFormat}
          data={dateFormatOptions}
          onChange={(value) => update({ dateFormat: value as EditableFlowValues['dateFormat'] })}
          label={
            <InfoLabel
              infoText={t('wizard.steps.settings.generalSettings.dateFormat.info')}
              data-testid={testIds.dateFormat.label}
            >
              {t('wizard.steps.settings.generalSettings.dateFormat.title')}
            </InfoLabel>
          }
          data-testid={testIds.dateFormat.input}
        />
        <Divider />
        <Checkbox
          checked={values.voiceInputEnabled}
          label={
            <InfoLabel
              infoText={t('wizard.steps.settings.generalSettings.voiceCapabilities.info')}
              data-testid={testIds.voiceCapabilities.label}
            >
              {t('wizard.steps.settings.generalSettings.voiceCapabilities.title')}
            </InfoLabel>
          }
          onChange={(e) => update({ voiceInputEnabled: e.target.checked })}
          data-testid={testIds.voiceCapabilities.input}
        />
        <Checkbox
          checked={values.singleInstance}
          label={
            <InfoLabel
              infoText={t('wizard.steps.settings.generalSettings.instanceLimit.info')}
              data-testid={testIds.instanceLimit.label}
            >
              {t('wizard.steps.settings.generalSettings.instanceLimit.title')}
            </InfoLabel>
          }
          onChange={(e) => update({ singleInstance: e.target.checked })}
          data-testid={testIds.instanceLimit.input}
        />
        <Checkbox
          checked={values.maxInspectors > 0}
          label={
            <>
              <InfoLabel
                infoText={t('wizard.steps.settings.generalSettings.userLimit.info')}
                data-testid={testIds.userLimit.label}
              >
                {t('wizard.steps.settings.generalSettings.userLimit.title')}
              </InfoLabel>
              {values.maxInspectors > 0 && (
                <NumberInput
                  hideControls={false}
                  value={values.maxInspectors}
                  min={1}
                  disabled={values.maxInspectors === 0}
                  onChange={(value) => update({ maxInspectors: Number(value) })}
                  data-testid={testIds.userLimit.capInput}
                />
              )}
            </>
          }
          onChange={(e) => update({ maxInspectors: e.target.checked ? 1 : 0 })}
          data-testid={testIds.userLimit.input}
        />
      </Stack>
    </Flex>
  );
};
