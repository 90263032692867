import { Box, Divider, Stack, noop } from '@mantine/core';
import { ContainerId, ReportedEventDef, ReportedEventsData, useContainerStore, useEventDefStore } from 'stores/wizard';
import { useMemo } from 'react';
import { BindingValue } from '@jargonic/event-definition-types';
import { useEventDefSelection } from '../../../../hooks';
import {
  EventDefActions,
  EventDefBinding,
  EventDefExposedInput,
  EventDefFilterableInput,
  EventDefMainEventInput,
  EventDefMandatoryInput,
  EventDefMapping,
  EventDefNameInput,
  EventDefPlaceholderInput,
  EventDefSynonymsInput,
  EventDefTitleInput,
  EventDefValidatedInput,
} from './components';
import classes from './EventDefGeneralSettings.module.css';

interface EventDefGeneralSettingsProps {
  eventDefId: string;
}

export const testIds = {
  wrapper: 'event-def-settings-general',
  divider: 'event-def-settings-general-divider',
};

function isDuplicateName(name: string, eventDefs: ReportedEventsData, eventDefId: string) {
  const eventDef = eventDefs[eventDefId];
  const labelId = eventDef?.labelId;
  const labelEventDefs = Object.values(eventDefs).filter((def) => def.labelId === labelId);
  return labelEventDefs.some((def) => def.name === name && def.id !== eventDefId);
}

export const EventDefGeneralSettings = ({ eventDefId }: EventDefGeneralSettingsProps) => {
  const { data, createEventDef, deleteEventDef, updateEventDef, updateMainEvent, updateEventMapping } =
    useEventDefStore([
      'data',
      'createEventDef',
      'deleteEventDef',
      'updateEventDef',
      'updateMainEvent',
      'updateEventMapping',
    ]);
  const { data: containers } = useContainerStore(['data']);
  const eventDef = data[eventDefId];
  const { onChangeSelectedEventDefId } = useEventDefSelection(eventDef?.labelId ?? '');

  const hasChildren = useMemo(() => Object.values(data).some((def) => def.parentId === eventDefId), [data, eventDefId]);
  const isDuplicatedName = useMemo(() => {
    if (!eventDef?.name) return false;
    return isDuplicateName(eventDef.name, data, eventDefId);
  }, [eventDef?.name, eventDefId]);

  const parentId = eventDef?.parentId ?? '';
  const isChild = Boolean(parentId);
  const isParent = !isChild;
  const parentEventDef = data[eventDef?.parentId ?? ''];

  const isParentWithValidation =
    isChild &&
    (!!parentEventDef?.validations?.length ||
      (parentEventDef?.values && parentEventDef.values.some((value) => value.valid !== undefined)));

  const containerPool = useMemo(() => {
    if (isChild && parentEventDef?.containerIds) {
      return parentEventDef.containerIds.map((id) => containers[id]);
    }
    return Object.values(containers);
  }, [containers, isChild, parentId, parentEventDef?.containerIds]);

  if (!eventDef) return null;

  const onAddChild = () => {
    const newEventDef = createEventDef(eventDef.labelId, eventDefId, eventDef.containerIds);

    onChangeSelectedEventDefId(newEventDef.id);
  };

  const onChangeContainers = (containerIds: ContainerId[]) => updateEventMapping(eventDefId, containerIds);

  const onChangeBindings = (values: BindingValue[]) => updateEventDef(eventDefId, { bindingValues: values });

  const onChangeMainEvent = () => updateMainEvent(eventDefId, eventDef.labelId);

  const onChangeMandatory = (checked: boolean) =>
    updateEventDef(eventDefId, { mandatory: checked, default: undefined });

  const onChangeFilterable = (checked: boolean) => updateEventDef(eventDefId, { filterable: checked });

  const onDelete = (withChildren: boolean) => deleteEventDef(eventDefId, withChildren);

  const handleStringUpdate = (updatedKey: keyof ReportedEventDef) => (value: string) =>
    updateEventDef(eventDefId, { [updatedKey]: value });

  const onSynonymsChange = (synonyms: string[]) => updateEventDef(eventDefId, { synonyms });

  return (
    <Box data-testid={testIds.wrapper}>
      <EventDefActions
        addChildEnabled={isParent}
        hasChildren={hasChildren}
        onAddChild={onAddChild}
        onDelete={onDelete}
      />
      <Box className={classes.grid}>
        <EventDefMapping
          allContainers={containerPool}
          labelId={eventDef.labelId}
          count={eventDef.containerIds.length}
          selectedIds={eventDef.containerIds}
          rootClassName={classes.mapping}
          onChange={onChangeContainers}
        />
        <EventDefSynonymsInput value={eventDef.synonyms} rootClassName={classes.synonyms} onChange={onSynonymsChange} />
        <EventDefNameInput
          duplicateError={isDuplicatedName}
          value={eventDef.name}
          rootClassName={classes.name}
          onChange={handleStringUpdate('name')}
        />
        <EventDefTitleInput
          value={eventDef.title}
          rootClassName={classes.title}
          onChange={handleStringUpdate('title')}
        />
        <EventDefPlaceholderInput
          value={eventDef.placeholder ?? ''}
          elementType={eventDef.elementType}
          rootClassName={classes.placeholder}
          onChange={handleStringUpdate('placeholder')}
        />
        {parentEventDef && (
          <EventDefBinding
            values={parentEventDef.values ?? []}
            selectedIds={eventDef.bindingValues ?? []}
            eventType={parentEventDef.valueType}
            isWithValidation={isParentWithValidation ?? false}
            onChange={onChangeBindings}
          />
        )}
      </Box>
      <Divider my='lg' data-testid={testIds.divider} />
      <Stack gap='lg'>
        {isParent && (
          <>
            <EventDefMainEventInput checked={eventDef.isMainEvent} onChange={onChangeMainEvent} />
            <EventDefExposedInput checked={Boolean(eventDef.exposed)} onChange={noop} />
          </>
        )}
        <EventDefMandatoryInput checked={Boolean(eventDef.mandatory)} onChange={onChangeMandatory} />
        <EventDefValidatedInput checked={Boolean(eventDef.validated)} onChange={noop} />
        <EventDefFilterableInput checked={Boolean(eventDef.filterable)} onChange={onChangeFilterable} />
      </Stack>
    </Box>
  );
};
