import { DEFAULT_THEME, MantineThemeOverride, rem } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';

export const componentTheme: Pick<MantineThemeOverride, 'components'> = {
  components: {
    Avatar: {
      defaultProps: {
        radius: 'xl',
      },
      styles: {
        placeholder: {
          fontWeight: 500,
        },
      },
    },
    Card: {
      defaultProps: {
        shadow: 'md',
        withBorder: true,
      },
    },
    Badge: {
      defaultProps: {
        radius: 'xs',
      },
    },
    Popover: {
      defaultProps: {
        withinPortal: true,
        shadow: 'sm',
      },
    },
    NumberInput: {
      defaultProps: {
        hideControls: true,
        rightSectionWidth: rem(36),
      },
    },
    Select: {
      defaultProps: {
        rightSection: <IconChevronDown />,
      },
    },
    Overlay: {
      defaultProps: {
        backgroundOpacity: 0.7,
      },
    },
    Notification: {
      defaultProps: {
        withBorder: true,
        styles: {
          icon: {
            padding: rem(4),
          },
        },
      },
    },
    ScrollArea: {
      defaultProps: {
        offsetScrollbars: true,
      },
      styles: () => ({
        scrollbar: {
          backgroundColor: DEFAULT_THEME.white,
        },
      }),
    },
  },
};
