import { Button, Group as MantineGroup, Text } from '@mantine/core';
import { ModalBase, modalBaseTestIds } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Group } from 'stores/userMgmt';

interface DeleteUserModalProps {
  opened: boolean;
  group: Group;
  onClose: () => void;
  onDelete: () => Promise<void>;
}

export const testIds = {
  title: modalBaseTestIds.title,
  closeButton: modalBaseTestIds.close,
  message: 'delete-group-message',
  modal: 'delete-group-modal',
  confirmButton: 'delete-group-confirm-button',
  cancelButton: 'delete-group-cancel-button',
};

export const DeleteGroupModal = ({ opened, group, onClose, onDelete }: DeleteUserModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { name } = group ?? { name: '' };

  const onConfirm = async () => {
    setLoading(true);
    await onDelete();
    setLoading(false);
    onClose();
  };

  return (
    <ModalBase
      closable={!loading}
      title={t('userMgmt.groups.groupDetails.panels.details.deleteGroup.modal.title', { name })}
      opened={opened}
      variant='warning'
      onClose={onClose}
      data-testid={testIds.modal}
    >
      <Text mb='lg' data-testid={testIds.message}>
        {t('userMgmt.groups.groupDetails.panels.details.deleteGroup.modal.message', { name: '' })}
        <Text span fw={700}>
          {group.name}.
        </Text>
      </Text>
      <MantineGroup justify='end'>
        <Button variant='subtle' onClick={onClose} disabled={loading} data-testid={testIds.cancelButton}>
          {t('common.cancel')}
        </Button>
        <Button loading={loading} onClick={onConfirm} data-testid={testIds.confirmButton}>
          {t('common.delete')}
        </Button>
      </MantineGroup>
    </ModalBase>
  );
};
