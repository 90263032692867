import { nanoid } from 'nanoid';
import { Container, ContainersData, MovePosition } from './containers.types';

export function generateContainers(
  names: string[],
  labelId: string,
  startingOrder: number,
  parentId?: string,
): Container[] {
  return names.map((name, index) => ({
    id: nanoid(),
    name,
    typeId: labelId,
    order: startingOrder + index,
    creationDate: Date.now(),
    attributes: {},
    synonyms: [],
    parentId,
    childrenIds: [],
  }));
}

export function getRootContainers(containers: ContainersData): Container[] {
  return Object.values(containers)
    .filter((container) => !container.parentId)
    .sort((a, b) => a.order - b.order);
}

export function findNextIndex(containers: ContainersData, parentId?: string) {
  if (parentId) {
    const parent = containers[parentId];
    return parent.childrenIds.length;
  }
  const rootContainers = getRootContainers(containers);
  return rootContainers.length;
}

export function applyRecursiveDeletion(containers: ContainersData, containerIds: string[]): ContainersData {
  containerIds.forEach((containerId) => {
    const container = containers[containerId];
    if (container) {
      const { childrenIds, parentId } = container;
      applyRecursiveDeletion(containers, childrenIds);
      if (parentId) {
        const parent = containers[parentId];
        parent.childrenIds = parent.childrenIds.filter((childId) => childId !== containerId);
      }
    }
    delete containers[containerId];
  });
  return containers;
}

export function findIsDescendant(containers: ContainersData, childId: string, ancestorId: string): boolean {
  const container = containers[childId];
  if (!container) return false;
  const { parentId } = container;
  if (!parentId) return false;
  if (parentId === ancestorId) return true;
  return findIsDescendant(containers, parentId, ancestorId);
}

export function getInsertionIndex(
  parentContainer: Container,
  position: MovePosition,
  siblingId?: string,
  isBackwardMove: boolean = false,
) {
  const siblingIndex = parentContainer.childrenIds.indexOf(siblingId!);

  // Dropping within the same container
  if (position === 'after') return isBackwardMove ? siblingIndex + 1 : siblingIndex;
  if (position === 'before') return isBackwardMove ? siblingIndex : siblingIndex - 1;

  // Dropping to a different container
  if (position === 'inside-after') return siblingIndex + 1;
  if (position === 'inside-before') return siblingIndex;

  // Dropping inside a different container without specifying sibling, position === 'inside'
  return parentContainer.childrenIds.length;
}

export function getRootInsertionIndex(containers: ContainersData, position: MovePosition, siblingId?: string) {
  const rootContainers = getRootContainers(containers);
  const siblingIndex = rootContainers.findIndex((cnt) => cnt.id === siblingId);
  return position === 'before' ? siblingIndex : siblingIndex + 1;
}
