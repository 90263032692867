import {
  GetTrainingExecutionQuery,
  GetTrainingExecutionQueryVariables,
  ListTrainingExecutionsInput,
  ListTrainingExecutionsQuery,
  ListTrainingExecutionsQueryVariables,
  ModelType,
} from '@ai-platform/common-types';
import { config } from 'services/config';
import { hermes } from 'services/hermes';
import { GraphQLResponse, StartTrainRequest, StartTrainResponse, TrainExecutionId } from 'stores/aiPlatform';
import {
  aiModelFromTrainingExecution,
  formattedAiMetrics,
  formattedAiTrainingMetrics,
  formattedAsrMetrics,
  formattedAsrTrainingMetrics,
} from '../aiModels.adapters';
import { POLLING_INTERVAL } from '../aiModels.const';
import { AiModel, MetricsRes } from '../aiModels.types';
import { getTrainingExecutionQuery, listTrainingExecutionsQuery } from './aiModels.gql';

export const aiModelsApi = {
  getAiModel: async (executionId: string): Promise<AiModel | undefined> => {
    const query = getTrainingExecutionQuery;
    const variables: GetTrainingExecutionQueryVariables = {
      executionId,
    };
    const {
      data: { data },
    } = await hermes.post<GraphQLResponse<GetTrainingExecutionQuery>>(`${config.aipApiHost}/appsync/query`, {
      query,
      variables,
    });
    return aiModelFromTrainingExecution(data?.getTrainingExecution);
  },
  getAiModels: async (listTrainingExecutionsInput: ListTrainingExecutionsInput): Promise<AiModel[] | undefined> => {
    const query = listTrainingExecutionsQuery;
    const variables: ListTrainingExecutionsQueryVariables = {
      listTrainingExecutionsInput,
    };
    const {
      data: { data },
    } = await hermes.post<GraphQLResponse<ListTrainingExecutionsQuery>>(`${config.aipApiHost}/appsync/query`, {
      query,
      variables,
    });
    return data?.listTrainingExecutions?.trainingExecutions
      ?.map(aiModelFromTrainingExecution)
      .filter((model): model is AiModel => !!model);
  },
  subscribeToAiModel: (id: string, callback: (aiModel: AiModel) => void) =>
    setInterval(async () => {
      const model = await aiModelsApi.getAiModel(id);
      if (model) callback(model);
    }, POLLING_INTERVAL),
  startModelTraining: async (request: StartTrainRequest): Promise<StartTrainResponse | undefined> => {
    const url = `${config.aipApiHost}/train/start`;
    const { data } = await hermes.post<StartTrainResponse>(url, request);
    return data;
  },
  getModelTrainingStepMetrics: async ({
    step,
    modelType,
    trainingExecutionId,
  }: {
    step: string;
    modelType: ModelType;
    trainingExecutionId: TrainExecutionId;
  }): Promise<MetricsRes | undefined> => {
    const url = `${config.aipApiHost}/train/metrics/${step}/${modelType}?executionId=${trainingExecutionId}`;
    const { data } = await hermes.get<{
      metrics: {
        span: Record<string, Record<string, number>>;
        iterationMetrics: Record<string, Record<string, number>>;
        generalMetrics: Record<string, Record<string, number>>;
      };
    }>(url);
    let adaptedResultMap = [] as {
      labels: {
        label: string;
        metrics?: Record<string, number[] | number>;
        value?: Record<string, number>;
      }[];
    }[];

    if (!data) {
      return undefined;
    }

    if (modelType === ModelType.ASR) {
      if (step === 'evaluation') {
        // @ts-expect-error
        adaptedResultMap = formattedAsrMetrics(data.metrics as Record<string, Record<string, number>>);
      }

      if (step === 'training') {
        adaptedResultMap = [
          {
            labels: [
              ...formattedAsrTrainingMetrics(data.metrics.iterationMetrics)[0].labels,
              ...formattedAsrMetrics(data.metrics.generalMetrics)[0].labels,
            ],
          },
        ];
      }
    } else {
      if (step === 'evaluation') {
        adaptedResultMap = formattedAiMetrics(data.metrics.span);
      }

      if (step === 'training') {
        // @ts-expect-error
        adaptedResultMap = formattedAiTrainingMetrics(data.metrics as Record<string, Record<string, number>>);
      }
    }

    return adaptedResultMap;
  },
};
