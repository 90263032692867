import { useEffect, useState } from 'react';
import { Stack } from '@mantine/core';
import {
  Container,
  EvenDefValidationId,
  EventDefNumericValidation,
  EventDefType,
  isSpecificValidationConflicting,
} from 'stores/wizard';
import { useScrollToLastItem } from 'utils/hooks/useScrollToLastItem';
import { ValidationItem } from './ValidationItem';

interface ValidationListProps {
  containerPool: Container[];
  rootClassName?: string;
  type: EventDefType.NUMBER | EventDefType.DATE | EventDefType.TIME_OF_DAY;
  validations: EventDefNumericValidation[];
  onChange: (validationId: EvenDefValidationId, values: Partial<EventDefNumericValidation>) => void;
  onDelete: (validationId: EvenDefValidationId) => void;
}

export const testIds = {
  list: 'validation-list',
};

export const ValidationList = ({
  containerPool,
  rootClassName,
  type,
  validations,
  onChange,
  onDelete,
}: ValidationListProps) => {
  const listRef = useScrollToLastItem<HTMLDivElement>(validations.length);
  const [conflicts, setConflicts] = useState<Record<EvenDefValidationId, boolean>>({});

  useEffect(() => {
    updateValidationsConflicts(validations);
  }, []);

  const updateValidationsConflicts = (validationsToCheck: EventDefNumericValidation[]) => {
    const updatedConflicts: Record<EvenDefValidationId, boolean> = {};

    validationsToCheck.forEach((validation) => {
      const hasConflict = isSpecificValidationConflicting(validationsToCheck, validation);
      updatedConflicts[validation.id] = hasConflict;
    });

    setConflicts(updatedConflicts);
  };

  const handleValidationChange = (validationId: EvenDefValidationId, changes: Partial<EventDefNumericValidation>) => {
    const updatedValidation = validations.find((v) => v.id === validationId);
    if (!updatedValidation) return;

    const updatedValidations: any[] = validations.map((v) => (v.id === validationId ? { ...v, ...changes } : v));
    const validationToCheck = updatedValidations.find((v: { id: string }) => v.id === validationId);

    if (!validationToCheck) return;

    onChange(validationId, changes);
    updateValidationsConflicts(updatedValidations as EventDefNumericValidation[]);
  };

  const handleDelete = (validationId: EvenDefValidationId) => () => {
    const updatedValidations = validations.filter((v) => v.id !== validationId);

    updateValidationsConflicts(updatedValidations);
    onDelete(validationId);
  };

  return (
    <Stack ref={listRef} classNames={{ root: rootClassName }} gap='tiny' data-testid={testIds.list}>
      {validations.map((validation) => (
        <ValidationItem
          key={validation.id}
          containerPool={containerPool}
          type={type}
          validation={validation}
          isConflicting={conflicts[validation.id]}
          onChange={(changes) => handleValidationChange(validation.id, changes)}
          onDelete={handleDelete(validation.id)}
        />
      ))}
    </Stack>
  );
};
