import { EventDefNumericValidation, EventDefType, NumericValidationType } from 'stores/wizard';
import { TimeSingleInput } from 'components';
import { NumberSingleInput } from './NumberSingleInput';
import { NumberRangeInput } from './NumberRangeInput';
import { DateSingleInput } from './DateSingleInput';
import { DateRangeInput } from './DateRangeInput';
import { TimeRangeInput } from './TimeRangeInput';

interface ConditionalInputProps {
  type: EventDefType.NUMBER | EventDefType.DATE | EventDefType.TIME_OF_DAY;
  validation: EventDefNumericValidation;
  onChange: (values: Partial<EventDefNumericValidation>) => void;
}

const { GREATER_THEN, LESS_THEN, IS_BETWEEN, IS_NOT_BETWEEN } = NumericValidationType;

export const ValidationItemInput = ({ type, validation, onChange }: ConditionalInputProps) => {
  switch (type) {
    case EventDefType.NUMBER:
      switch (validation.validationType) {
        case GREATER_THEN:
        case LESS_THEN:
          return (
            <NumberSingleInput
              value={validation.expectedDef.value || null}
              onChange={(value) => onChange({ expectedDef: { value } })}
            />
          );
        case IS_BETWEEN:
        case IS_NOT_BETWEEN:
          return (
            <NumberRangeInput
              max={validation.expectedDef.max || null}
              min={validation.expectedDef.min || null}
              onChange={(min, max) => onChange({ expectedDef: { min, max } })}
            />
          );
        default:
          return null;
      }
    case EventDefType.DATE:
      switch (validation.validationType) {
        case GREATER_THEN:
        case LESS_THEN:
          return (
            <DateSingleInput
              value={validation.expectedDef.value || null}
              onChange={(value) => onChange({ expectedDef: { value } })}
            />
          );
        case IS_BETWEEN:
        case IS_NOT_BETWEEN:
          return (
            <DateRangeInput
              max={validation.expectedDef.max || null}
              min={validation.expectedDef.min || null}
              onChange={(min, max) => onChange({ expectedDef: { min, max } })}
            />
          );
        default:
          return null;
      }
    case EventDefType.TIME_OF_DAY:
      switch (validation.validationType) {
        case GREATER_THEN:
        case LESS_THEN:
          return (
            <TimeSingleInput
              value={validation.expectedDef.value ?? null}
              error={!validation.expectedDef.value}
              style={{ width: '70%' }}
              onChange={(value) => onChange({ expectedDef: { value } })}
            />
          );
        case IS_BETWEEN:
        case IS_NOT_BETWEEN:
          return (
            <TimeRangeInput
              max={validation.expectedDef.max ?? null}
              min={validation.expectedDef.min ?? null}
              onChange={(min, max) => onChange({ expectedDef: { min, max } })}
            />
          );
        default:
          return null;
      }
    default:
      return null;
  }
};
