import { ModelType } from '@ai-platform/common-types';
import { noop } from '@mantine/core';
import { AiModelMetricsTable, metricsTableTestIds } from 'pages/aiPlatform/components';
import {
  columns as defaultTrainingColumns,
  evaluationStepMetricsAsr,
} from 'pages/aiPlatform/components/AiModelMetricsTable/AiModelMetricsTable.columns';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TrainingPipelineRouteParams } from 'routes/routes.config';
import { TrainingStepStatus, useAiModel, useAiModelStore, useTrainingSteps } from 'stores/aiPlatform/aiModels';
import classes from '../../TrainingPipelinePage.module.css';
import { StepCommonContent, testIds as commonContentTestIds } from '../StepCommonContent/StepCommonContent';
import { TestingContentHeader, testIds as headerTestIds } from './TestingContentHeader';

export const testIds = {
  completedContent: 'testing-content-completed',
  downloadIcon: 'testing-content-download-icon',
  commonContent: commonContentTestIds,
  metrics: metricsTableTestIds,
  header: headerTestIds,
};

export const TestingContent = () => {
  const { t } = useTranslation();
  const { modelId } = useParams() as TrainingPipelineRouteParams;
  const { test: testingStep } = useTrainingSteps(modelId) ?? {};
  const aiModel = useAiModel(modelId);
  const { fetchAiModelStepMetrics } = useAiModelStore(['fetchAiModelStepMetrics']);

  useEffect(() => {
    if (testingStep?.status === TrainingStepStatus.COMPLETED && aiModel?.type && !testingStep.results?.length) {
      fetchAiModelStepMetrics('test', aiModel.type, modelId);
    }
  }, [testingStep, aiModel]);

  const columns = useMemo(
    () => (aiModel?.type === ModelType.ASR ? evaluationStepMetricsAsr : defaultTrainingColumns),
    [aiModel?.type],
  );

  switch (testingStep?.status) {
    case TrainingStepStatus.IN_PROGRESS:
      return (
        <StepCommonContent
          key={TrainingStepStatus.IN_PROGRESS}
          title={t('trainingPipelinePage.steps.test.inProgressTitle')}
          message={t('trainingPipelinePage.steps.contentMessage')}
          className={classes.greenBorder}
          onAbort={noop}
          onPause={noop}
        />
      );
    case TrainingStepStatus.PAUSED:
      return (
        <StepCommonContent
          key={TrainingStepStatus.PAUSED}
          title={t('trainingPipelinePage.steps.pausedTitle')}
          message={t('trainingPipelinePage.steps.contentMessage')}
          className={classes.greenBorder}
          onAbort={noop}
          onResume={noop}
        />
      );

    case TrainingStepStatus.FAILED:
      return (
        <StepCommonContent
          key={TrainingStepStatus.FAILED}
          title={t('trainingPipelinePage.steps.failedTitle')}
          message={t('trainingPipelinePage.steps.failedMessage')}
          className={classes.redBorder}
          onAbort={noop}
          onReset={noop}
        />
      );
    case TrainingStepStatus.COMPLETED:
      return (
        <AiModelMetricsTable
          pages={testingStep.results}
          columns={columns}
          withFooter={false}
          classNames={{ paper: classes.greenBorder, table: classes.metricsTable }}
          header={
            <TestingContentHeader
              failedSampleCount={testingStep.failedSamplesCount}
              failedSamplesLink={testingStep.failedSamplesLink}
            />
          }
        />
      );
    default:
      return null;
  }
};
