import { GetCustomersResponse } from '@jargonic/customer-types';
import { config } from 'services/config';
import { hermes } from 'services/hermes';
import qs from 'qs';
import { CreateCustomerPayload, Customer } from './customers.types';

const customersUrl = `${config.jargonicApiUrl}/customers`;

async function getCustomersRecursively(customers: Customer[], nextToken?: string) {
  const query = {
    nextToken,
  };
  const url = `${customersUrl}?${qs.stringify(query)}`;
  const { data } = await hermes.get<GetCustomersResponse>(url, { fallback: { items: [] } });

  customers.push(...data.items);

  if (data.nextToken) await getCustomersRecursively(customers, data.nextToken);
  return customers;
}

export const customersApi = {
  getCustomers: () => getCustomersRecursively([]),

  createCustomer: async (customer: CreateCustomerPayload): Promise<Customer | undefined> => {
    const { data } = await hermes.post<Customer>(`${config.jargonicApiUrl}/customers`, customer);
    return data;
  },
};
