// TODO: Get these types from AI-Platform backend
import { ModelType, State } from '@ai-platform/common-types';
import { UserIdentity } from '@flow/flow-backend-types';

export enum AiModelStatus {
  COMPLETED = 'completed',
  IN_TRAINING = 'inTraining',
  FAILED = 'failed',
  PAUSED = 'paused',
  ABORTED = 'aborted',
  DECLINED = 'declined',
}

export enum AiDataset {
  TRAINING = 'training',
  TESTING = 'testing',
  EVALUATION = 'evaluation',
}

export enum TrainingStepStatus {
  COMPLETED = 'completed',
  IN_PROGRESS = 'inProgress',
  PAUSED = 'paused',
  FAILED = 'failed',
  PENDING = 'pending',
}

export type AiModelId = string;

export interface AiModel<Type extends ModelType = ModelType> {
  id: AiModelId;
  createdAt: number;
  createdBy: UserIdentity;
  status: AiModelStatus;
  type: Type;
  trainingSteps: TrainingSteps;
}

export interface AiModelMetrics {
  precision: number;
  recall: number;
  f1_score: number;
  support: number;
}

export interface LabelMetrics {
  label: string;
  metrics: AiModelMetrics;
}
export interface LabelValues {
  label: string;
  value: number;
}

export interface IterationResults {
  labels: LabelMetrics[] | LabelValues[];
}

/** This is a virtual step indicating the status of the process until an actual step has started */
export interface WarmupStep {
  status: TrainingStepStatus;
}

export type DatasetCreationStep =
  | {
      status: TrainingStepStatus.COMPLETED;
      trainingUri: string;
      testingUri: string;
      evaluationUri: string;
    }
  | {
      status:
        | TrainingStepStatus.IN_PROGRESS
        | TrainingStepStatus.PAUSED
        | TrainingStepStatus.FAILED
        | TrainingStepStatus.PENDING;
    };
export type DatasetCreationStepCompleted = Extract<DatasetCreationStep, { status: TrainingStepStatus.COMPLETED }>;

export type TrainingStep =
  | {
      status: TrainingStepStatus.COMPLETED | TrainingStepStatus.IN_PROGRESS | TrainingStepStatus.PAUSED;
      results: IterationResults[];
    }
  | {
      status: TrainingStepStatus.FAILED | TrainingStepStatus.PENDING;
    };
export type TrainingStepCompleted = Extract<TrainingStep, { status: TrainingStepStatus.COMPLETED }>;

export type TestingStep =
  | {
      status: TrainingStepStatus.COMPLETED;
      results: IterationResults[];
      failedSamplesCount: number;
      failedSamplesLink?: string;
    }
  | {
      status:
        | TrainingStepStatus.IN_PROGRESS
        | TrainingStepStatus.PAUSED
        | TrainingStepStatus.FAILED
        | TrainingStepStatus.PENDING;
    };
export type TestingStepCompleted = Extract<TestingStep, { status: TrainingStepStatus.COMPLETED }>;

export interface TrainingSteps {
  warmup: WarmupStep;
  dataset: DatasetCreationStep;
  train: TrainingStep;
  test: TestingStep;
}

export interface AiModelSubsciptionOptions {
  onComplete?: () => void;
  unsubscribeOnFinish?: boolean;
}

export enum AiModelEnvironment {
  INTERNAL_DEV = 'internal-dev',
  INTERNAL_QA = 'internal-qa',
  INTERNAL_AUTOMATION = 'internal-automation',
  PROD = 'prod',
}

export type AiModelDataTooltip = {
  triggeredBy: string;
  startTimestamp: number;
  link: string;
  executionId: string;
};

export type PublishedModelTrainingData = {
  language: string;
  dataGenerationId: string;
  flowVersion: string;
  flowId: string;
  tenant: string;
  trainingInfo: AiModelDataTooltip;
  dataGenerationInfo: AiModelDataTooltip;
};
export interface AiPublishedModel<Type extends ModelType = ModelType> {
  publishExecutionId: AiModelId;
  publishedDate: number;
  publishedBy: string;
  status: State;
  environment: AiModelEnvironment;
  type: Type;
  modelData: PublishedModelTrainingData;
}

export type MetricsRes = {
  labels: {
    label: string;
    metrics?: Record<string, number[] | number>;
    value?: Record<string, number>;
  }[];
}[];
