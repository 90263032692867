import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Combobox, useCombobox } from '@mantine/core';
import { Customer, CustomerId } from '@jargonic/customer-types';
import { CustomerSelectTarget, testIds as targetTestIds } from './CustomerSelectTarget';
import { CustomerOption, testIds as optionTestIds } from './CustomerSelectOption';
import { CustomerSelectFooter, testIds as footerTestIds } from './CustomerSelectFooter';

interface CustomerSelectProps {
  customers: Customer[];
  selectedCustomerId?: CustomerId;
  onSelectCustomer: (customerId: CustomerId) => void;
  onViewAll: () => void;
}

export const testIds = {
  dropdown: 'customer-select-dropdown',
  target: targetTestIds,
  option: optionTestIds,
  footer: footerTestIds,
};

export const CustomerSelect = ({ customers, selectedCustomerId, onSelectCustomer, onViewAll }: CustomerSelectProps) => {
  const { t } = useTranslation();
  const combobox = useCombobox();
  const selectedCustomer = useMemo(
    () => customers.find(({ id }) => id === selectedCustomerId),
    [selectedCustomerId, customers],
  );

  const onSelect = (customerId: CustomerId) => {
    onSelectCustomer(customerId);
    combobox.closeDropdown();
  };

  const onClickViewAll = () => {
    onViewAll();
    combobox.closeDropdown();
  };

  return (
    <Combobox store={combobox} position='bottom-start' onOptionSubmit={onSelect}>
      <CustomerSelectTarget customer={selectedCustomer} onClick={combobox.toggleDropdown} />
      <Combobox.Dropdown className='!w-fit' data-testid={testIds.dropdown}>
        <Combobox.Options>
          <Combobox.Group
            className='overflow-auto'
            classNames={{ groupLabel: 'after:hidden' }}
            mah={400}
            label={t('header.select.cta')}
          >
            {customers.map((customer) => (
              <CustomerOption key={customer.id} customer={customer} />
            ))}
          </Combobox.Group>
        </Combobox.Options>
        <CustomerSelectFooter onViewAll={onClickViewAll} />
      </Combobox.Dropdown>
    </Combobox>
  );
};
