import { useTranslation } from 'react-i18next';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { Container, ContainerId } from 'stores/wizard';
import { Box, Button, Popover, Select } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { orderPredicate } from 'utils';
import { InfoLabel } from 'components';

interface ReorderContainerMenuProps extends PropsWithChildren {
  containers: Container[];
  opened: boolean;
  onClose: () => void;
  onApply: (containerId: ContainerId, parentId: ContainerId | null, positionAfterId: ContainerId | null) => void;
}

export const testIds = {
  menu: 'reorder-container-menu',
  containerSelect: 'reorder-container-menu-container-select',
  parentSelect: 'reorder-container-menu-parent-select',
  afterSelect: 'reorder-container-menu-after-select',
  submit: 'reorder-container-menu-submit',
};

interface ReorderContainerFormState {
  containerId: ContainerId | null;
  parentId: ContainerId | null;
  afterId: ContainerId | null;
}

export const ReorderContainerMenu = ({ children, containers, opened, onClose, onApply }: ReorderContainerMenuProps) => {
  const { t } = useTranslation();
  const form = useForm<ReorderContainerFormState>({
    initialValues: { containerId: null, parentId: null, afterId: null },
    validate: {
      containerId: isNotEmpty(),
    },
  });

  const containerOptions = useMemo(() => containers.map((c) => ({ value: c.id, label: c.name })), [containers]);
  const parentOptions = useMemo(
    () => containerOptions.filter((c) => c.value !== form.values.containerId),
    [form.values.containerId],
  );
  const siblingOptions = useMemo(() => {
    const parent = containers.find((c) => c.id === form.values.parentId);
    if (!parent) return [];
    const childrenIdSet = new Set(parent.childrenIds);
    return containers
      .filter((c) => childrenIdSet.has(c.id) && c.id !== form.values.containerId)
      .sort(orderPredicate)
      .map((c) => ({ value: c.id, label: c.name }));
  }, [form.values.containerId, form.values.parentId]);

  const handleClose = () => {
    onClose();
    form.reset();
  };

  const onSubmit = ({ containerId, parentId, afterId }: ReorderContainerFormState) => {
    if (!containerId) return;
    onApply(containerId, parentId, afterId);
    handleClose();
  };

  useEffect(() => {
    const container = containers.find((c) => c.id === form.values.containerId);
    if (!container) return;
    form.setValues({ parentId: container.parentId });
  }, [form.values.containerId]);

  useEffect(() => {
    const { parentId, containerId } = form.values;
    const parent = containers.find((c) => c.id === parentId);
    if (!parent) return;
    const siblings = containers.filter((c) => c.parentId === parentId).sort(orderPredicate);
    const containerPositionIndex = siblings.findIndex((c) => c.id === containerId);
    const siblingBefore = containerPositionIndex > 0 ? siblings[containerPositionIndex - 1].id : null;
    form.setValues({ afterId: siblingBefore });
  }, [form.values.parentId]);

  return (
    <Popover opened={opened} position='left' onClose={handleClose}>
      <Popover.Target>{children}</Popover.Target>
      <Popover.Dropdown data-testid={testIds.menu}>
        <Box className='flex flex-col gap-2' component='form' onSubmit={form.onSubmit(onSubmit)}>
          <Select
            data={containerOptions}
            title={t('wizard.steps.labeling.containerTable.moveItemMenu.containerList.title')}
            label={t('wizard.steps.labeling.containerTable.moveItemMenu.containerList.title')}
            placeholder={t('wizard.steps.labeling.containerTable.moveItemMenu.containerList.placeholder')}
            comboboxProps={{ withinPortal: false }}
            allowDeselect
            searchable
            clearable
            withAsterisk
            {...form.getInputProps('containerId')}
            data-testid={testIds.containerSelect}
          />
          <Select
            data={parentOptions}
            title={t('wizard.steps.labeling.containerTable.moveItemMenu.parentList.title')}
            label={t('wizard.steps.labeling.containerTable.moveItemMenu.parentList.title')}
            placeholder={t('wizard.steps.labeling.containerTable.moveItemMenu.parentList.placeholder')}
            comboboxProps={{ withinPortal: false }}
            allowDeselect
            searchable
            clearable
            {...form.getInputProps('parentId')}
            data-testid={testIds.parentSelect}
          />
          <Select
            data={siblingOptions}
            title={t('wizard.steps.labeling.containerTable.moveItemMenu.targetList.title')}
            label={
              <InfoLabel infoText={t('wizard.steps.labeling.containerTable.moveItemMenu.targetList.tooltip')}>
                {t('wizard.steps.labeling.containerTable.moveItemMenu.targetList.title')}
              </InfoLabel>
            }
            placeholder={t('wizard.steps.labeling.containerTable.moveItemMenu.targetList.placeholder')}
            comboboxProps={{ withinPortal: false }}
            allowDeselect
            searchable
            clearable
            {...form.getInputProps('afterId')}
            data-testid={testIds.afterSelect}
          />
          <Button type='submit' disabled={!form.isValid()} data-testid={testIds.submit}>
            {t('wizard.steps.labeling.containerTable.moveItemMenu.submitButton')}
          </Button>
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};
