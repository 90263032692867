import { ChangeEventHandler, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CloseButton, Group, TextInput, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { MRT_TableOptions } from 'mantine-react-table';
import { IconSearch, IconTrash, IconPlus, IconArrowsVertical } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { Container } from 'stores/wizard';
import { ContainerTableState } from '../ContainerTable.types';
import { CreateContainerMenu, testIds as addMenuTestIds } from './CreateContainerMenu/CreateContainerMenu';
import { ReorderContainerMenu, testIds as moveMenuTestIds } from './ReorderContainerMenu/ReorderContainerMenu';

export const testIds = {
  title: 'container-table-title',
  addItem: {
    button: 'container-table-add-item-button',
    icon: 'container-table-add-item-icon',
    menu: addMenuTestIds,
  },
  moveItem: {
    button: 'container-table-move-item-button',
    icon: 'container-table-move-item-icon',
    menu: moveMenuTestIds,
  },
  deleteSelected: {
    button: 'container-table-delete-selected-button',
    icon: 'container-table-delete-selected-icon',
  },
  search: {
    input: 'container-table-search-input',
    icon: 'container-table-search-icon',
    clearButton: 'container-table-search-clear-button',
  },
};

export const ContainerTableActions: MRT_TableOptions<Container>['renderTopToolbar'] = ({ table }) => {
  const { t } = useTranslation();

  const state = table.getState() as ContainerTableState;
  const [newItemMenuOpened, { open, close }] = useDisclosure();
  const [moveItemMenuOpened, { open: openMove, close: closeMove }] = useDisclosure();
  const { globalFilter } = state;
  const containers = table.getRowModel().flatRows.map((r) => r.original);

  const selectedRows = table.getSelectedRowModel().flatRows;
  const disabled = !selectedRows.length;

  const onClickDelete = () => {
    const { onDelete } = state;
    onDelete(selectedRows.map((r) => r.id));
    table.resetRowSelection();
  };

  const onChangeSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { onSearch } = state;
    onSearch(event.currentTarget.value);
  };

  const onResetSearch = () => {
    const { onSearch } = state;
    onSearch('');
  };

  const onCreateContainer: ComponentProps<typeof CreateContainerMenu>['onCreate'] = ({ name, labelId, parentId }) => {
    const { onCreate } = state;
    onCreate(name, labelId, parentId);
  };

  return (
    <Box p='sm'>
      <Group>
        <Title className='grow' order={4} data-testid={testIds.title}>
          {t('wizard.steps.labeling.containerTable.title')}
        </Title>
        <CreateContainerMenu
          containers={containers}
          labels={state.labels}
          opened={newItemMenuOpened}
          onClose={close}
          onCreate={onCreateContainer}
        >
          <ActionButton
            label={t('wizard.steps.labeling.containerTable.addNew')}
            onClick={open}
            data-testid={testIds.addItem.button}
          >
            <IconPlus data-testid={testIds.addItem.icon} />
          </ActionButton>
        </CreateContainerMenu>
        <ReorderContainerMenu
          containers={containers}
          opened={moveItemMenuOpened}
          onClose={closeMove}
          onApply={state.onReorder}
        >
          <ActionButton
            label={t('wizard.steps.labeling.containerTable.moveItem')}
            onClick={openMove}
            data-testid={testIds.moveItem.button}
          >
            <IconArrowsVertical data-testid={testIds.moveItem.icon} />
          </ActionButton>
        </ReorderContainerMenu>
        <ActionButton
          label={t('wizard.steps.labeling.containerTable.deleteSelected')}
          disabled={disabled}
          onClick={onClickDelete}
          data-testid={testIds.deleteSelected.button}
        >
          <IconTrash data-testid={testIds.deleteSelected.icon} />
        </ActionButton>
      </Group>
      <TextInput
        placeholder={t('wizard.steps.labeling.containerTable.search')}
        rightSection={
          <CloseButton hidden={!globalFilter} onClick={onResetSearch} data-testid={testIds.search.clearButton} />
        }
        leftSection={<IconSearch size={18} data-testid={testIds.search.icon} />}
        mt='xs'
        value={globalFilter}
        variant='filled'
        onChange={onChangeSearch}
        data-testid={testIds.search.input}
      />
    </Box>
  );
};
