import { FlowExpiration, FlowExpirationType } from '@jargonic/flows-types';
import i18n from 'services/i18n';
import { ValidationOopsie } from '../wizard.types';
import { FlowMetadataField, FlowSettingsData } from './flowSettings.types';

/** Validate that no fields have the same name */
function validateNoDuplicateMetadataFieldNames(fields: FlowMetadataField[]): ValidationOopsie[] {
  const names = new Set<string>();
  const nameWarnings = fields.reduce<Set<string>>((acc, field) => {
    if (names.has(field.title))
      acc.add(i18n.t('wizard.steps.settings.validations.duplicateMetadataFieldName', { name: field.title }));
    else names.add(field.title);
    return acc;
  }, new Set());
  return Array.from(nameWarnings).map((text) => ({ text }));
}

/** Validate that no fields are missing names */
function validateNoMissingNames(fields: FlowMetadataField[]): ValidationOopsie[] {
  const fieldsWithNoNames = fields.filter((field) => !field.title);
  const count = fieldsWithNoNames.length;
  return count ? [{ text: i18n.t('wizard.steps.settings.validations.metadataFieldMissingName', { count }) }] : [];
}

/** Validate that the flow has a name */
function validateNoMissingFlowName(name: string): ValidationOopsie[] {
  return name ? [] : [{ text: i18n.t('wizard.steps.settings.validations.flowMissingName') }];
}

/** Validate that the unique identifier field is mandatory */
function validateMandatoryUniqueIdField(fields: FlowMetadataField[], uniqueIdFieldId?: string): ValidationOopsie[] {
  const uniqueIdField = fields.find((field) => field.id === uniqueIdFieldId);
  return uniqueIdFieldId && !uniqueIdField?.required
    ? [{ text: i18n.t('wizard.steps.settings.validations.uniqueIdFieldNotMandatory') }]
    : [];
}

/** Validate that the expiration has a value if it's not set to never */
function validateNoMissingExpiration(expiration?: FlowExpiration): ValidationOopsie[] {
  if (!expiration) return [];

  const { type } = expiration;

  if (type === FlowExpirationType.NEVER) return [];

  if (type === FlowExpirationType.ELAPSED_TIME) {
    if (expiration.value === undefined) {
      return [{ text: i18n.t('wizard.steps.settings.validations.missingExpirationValue') }];
    }
  }

  if (type === FlowExpirationType.LIST_TIME_OF_DAY) {
    if (expiration.values === undefined || expiration.values.length === 0) {
      return [{ text: i18n.t('wizard.steps.settings.validations.missingExpirationValue') }];
    }
  }

  return [];
}

/** Validate that the expiration values are valid */
function validateValidExpirationValues(expiration?: FlowExpiration): ValidationOopsie[] {
  if (!expiration) return [];

  const { type } = expiration;

  if (type === FlowExpirationType.ELAPSED_TIME) {
    if (expiration.value < 1) {
      return [{ text: i18n.t('wizard.steps.settings.validations.minHoursExpirationValue') }];
    }
  }

  return [];
}

export function validateFlowSettings(data: FlowSettingsData): ValidationOopsie[] {
  const metadataFields = [...data.metadata.preInspectionFields, ...data.metadata.postInspectionFields];

  return [
    validateNoMissingFlowName(data.flow.name),
    validateNoMissingNames(metadataFields),
    validateNoDuplicateMetadataFieldNames(metadataFields),
    validateMandatoryUniqueIdField(metadataFields, data.metadata.uniqueIdentifierFieldId),
    validateNoMissingExpiration(data.flow.expiration as FlowExpiration),
    validateValidExpirationValues(data.flow.expiration as FlowExpiration),
  ].flatMap((x) => x);
}
