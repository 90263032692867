import { Card, Image, Text, Title, Tooltip } from '@mantine/core';
import { Customer } from '@jargonic/customer-types';
import { useRef } from 'react';
import { useIsTruncated } from 'utils/hooks/useIsTruncated';
import classes from './CustomerModal.module.css';

interface CustomerCardProps {
  customer: Customer;
  onClick?: () => void;
}

export const testIds = {
  getCardTestId: (id: string) => `customer-card-${id}`,
  logo: 'customer-card-logo',
  name: 'customer-card-name',
  tooltip: 'customer-card-tooltip',
  industry: 'customer-card-industry',
};

export const CustomerCard = ({ customer, onClick }: CustomerCardProps) => {
  const textRef = useRef<HTMLDivElement>(null);
  const isTruncated = useIsTruncated(textRef);

  const titleContent = (
    <Title ref={textRef} className='truncate' order={5} w='100%' data-testid={testIds.name}>
      {customer.name}
    </Title>
  );

  return (
    <Card
      tabIndex={0}
      className={classes.card}
      color='green'
      radius={8}
      onClick={onClick}
      data-testid={testIds.getCardTestId(customer.id)}
    >
      <Image
        src={customer.logo}
        alt={customer.name}
        fallbackSrc='/CustomerFallback.svg'
        w='auto'
        fit='contain'
        data-testid={testIds.logo}
      />
      <Tooltip label={customer.name} openDelay={500} multiline disabled={!isTruncated} data-testid={testIds.tooltip}>
        {titleContent}
      </Tooltip>
      <Text fw={400} size='sm' c='gray.5' data-testid={testIds.industry}>
        {customer.industry}
      </Text>
    </Card>
  );
};
