import { useIsFirstRender } from '@mantine/hooks';
import { useRef, RefObject, useLayoutEffect } from 'react';

/**
 * Custom hook to automatically scroll to the last item in a list when the list's length changes.
 *
 * This hook sets a ref on the list container and, whenever the length of the list changes,
 * it scrolls the last item of the list into view smoothly.
 *
 * @template T - The type of the HTML element the ref will be attached to.
 *
 * @param itemsLength - The current number of items in the list.
 *
 * @returns A ref object to be attached to the list container element.
 *
 * @example
 * ```
 * import { useScrollToLastItem } from '../hooks';
 *
 * const MyListComponent = ({ items }) => {
 *   const listRef = useScrollToLastItem<HTMLDivElement>(items.length);
 *
 *   return (
 *     <div ref={listRef}>
 *       {items.map(item => (
 *         <div key={item.id}>{item.name}</div>
 *       ))}
 *     </div>
 *   );
 * };
 * ```
 */
export const useScrollToLastItem = <T extends HTMLElement>(itemsLength: number): RefObject<T> => {
  const isFirstRender = useIsFirstRender();
  const listRef = useRef<T | null>(null);

  useLayoutEffect(() => {
    if (!isFirstRender && itemsLength > 0) {
      const lastItem = listRef.current?.lastElementChild as HTMLElement | null;
      if (lastItem) {
        lastItem.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [itemsLength]);

  return listRef;
};
