import {
  Button,
  Divider,
  Group,
  Input,
  ModalBaseProps,
  NumberInput,
  Radio,
  SegmentedControl,
  Stack,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useLocalStorage } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconAlertTriangle } from '@tabler/icons-react';
import { LanguageSelect, languageSelectTestIds, ModalBase } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DataGenerationRouteParams } from 'routes/routes.config';
import { DataGenUISettings, fullGenSamplesNumberKey, useAiDataSourcesStore } from 'stores/aiPlatform';
import { useFlow } from 'stores/flows';

type FormState = {
  customerConfigLocation?: string;
  customerConfigPromptsLocation?: string;
  customerPredefinedTemplateLocation?: string;
  generationMethod: 'template' | 'llm';
  language: string;
  initialSamples: number;
  samplesCount: number;
} & (
  | {
      inputSource: 'external';
      customerConfigLocation: string;
      customerConfigPromptsLocation: string;
      customerPredefinedTemplateLocation?: string;
    }
  | {
      inputSource: 'internal';
    }
);

interface TextDataGenerationSettingsModalProps {
  opened: boolean;
  settings?: DataGenUISettings;
  samplesInputDisableOverride?: boolean;
  sampleExecutionId?: string;
  onClose: () => void;
  modalBaseProps?: Partial<ModalBaseProps>;
}

export const SAMPLE_COUNTS = {
  initial: 30,
  full: 10000,
};

export const testIds = {
  wrapper: 'text-data-generation-settings-modal',
  inputSource: 'text-data-generation-settings-modal-input-source',
  customerConfigLocation: 'text-data-generation-settings-modal-customer-config-location',
  customerConfigPromptsLocation: 'text-data-generation-settings-modal-customer-config-prompts-location',
  customerPredefinedTemplateLocation: 'text-data-generation-settings-modal-predefined-templates-location',
  generationMethod: 'text-data-generation-settings-modal-generation-method',
  language: languageSelectTestIds,
  initialSamples: 'text-data-generation-settings-modal-initial-samples',
  samplesCount: 'text-data-generation-settings-modal-samples-count',
  cancelButton: 'text-data-generation-settings-modal-cancel-button',
  submitButton: 'text-data-generation-settings-modal-submit-button',
};

export const TextDataGenerationSettingsModal = ({
  opened,
  settings,
  samplesInputDisableOverride,
  sampleExecutionId,
  onClose,
  modalBaseProps,
}: TextDataGenerationSettingsModalProps) => {
  const { t } = useTranslation();
  const hasSettings = !!settings;
  const { customerId, flowId } = useParams() as DataGenerationRouteParams;
  const [submitting, setSubmitting] = useState(false);
  const [cachedFullGenerationSamplesCount, setCachedFullGenerationSamplesCount] = useLocalStorage<number | null>({
    key: fullGenSamplesNumberKey,
    defaultValue: null,
    getInitialValueInEffect: false,
  });
  const derivedSamplesCount = deriveSamplesCount(settings, cachedFullGenerationSamplesCount);

  const form = useForm<FormState>({
    initialValues: {
      inputSource: 'external',
      generationMethod: settings?.textGenSettings?.structuredTextGen === false ? 'llm' : 'template',
      language: settings?.language ?? 'en-US',
      initialSamples: settings?.initialSamplesNumber ?? settings?.samplesCount ?? 30,
      samplesCount: derivedSamplesCount,
      customerConfigLocation: settings?.customerConfigLocation ?? '',
      customerConfigPromptsLocation: settings?.customerConfigPromptsLocation ?? '',
      customerPredefinedTemplateLocation: settings?.customerPredefinedTemplateLocation ?? '',
    },
    validate: {
      initialSamples: (value) =>
        (value < SAMPLE_COUNTS.initial &&
          t('dataGenerationPage.generateModal.initialSamplesInput.tooLowError', { count: SAMPLE_COUNTS.initial })) ||
        (value > SAMPLE_COUNTS.full &&
          t('dataGenerationPage.generateModal.initialSamplesInput.tooHighError', { count: SAMPLE_COUNTS.full })),
      samplesCount: (value) =>
        (value < SAMPLE_COUNTS.initial &&
          t('dataGenerationPage.generateModal.numberOfSamplesInput.tooLowError', { count: SAMPLE_COUNTS.initial })) ||
        (value > SAMPLE_COUNTS.full &&
          t('dataGenerationPage.generateModal.numberOfSamplesInput.tooHighError', { count: SAMPLE_COUNTS.full })),
    },
    validateInputOnBlur: true,
    enhanceGetInputProps: () => ({ disabled: hasSettings }),
  });
  const flow = useFlow(flowId);
  const { createAiDataSource } = useAiDataSourcesStore(['createAiDataSource']);

  const onSubmit = async (formState: FormState) => {
    setSubmitting(true);
    // Currently internal input is unsupported
    if (formState.inputSource === 'internal') return;

    // TODO: Temporary fix for this tenant until properly fixed in BE
    const tenantId = customerId.replace(/^aiola$/, 'internal-dev');
    const {
      generationMethod,
      initialSamples,
      language,
      samplesCount,
      customerConfigPromptsLocation: customerConfigPromptsSourceInput,
      customerConfigLocation: customerConfigSourceInput,
      customerPredefinedTemplateLocation: predefinedTemplatesSourceInput,
    } = formState;
    const isSampleGeneration = !sampleExecutionId;
    const customerConfigPromptsLocation =
      customerConfigPromptsSourceInput || t('dataGenerationPage.generateModal.customerConfigPromptsInput.placeholder');
    const customerConfigLocation =
      customerConfigSourceInput || t('dataGenerationPage.generateModal.customerConfigInput.placeholder');
    const customerPredefinedTemplateLocation =
      predefinedTemplatesSourceInput || t('dataGenerationPage.generateModal.predefinedTemplateInput.placeholder');
    if (isSampleGeneration) {
      setCachedFullGenerationSamplesCount(samplesCount);
    }

    const response = await createAiDataSource({
      flowId,
      flowVersion: flow.activeVersion!,
      tenantId,
      sampleExecutionId,
      onlyTextSamples: true,
      dataGenSettings: {
        __typename: 'DataGenerationSettings',
        customerConfigPromptsLocation,
        customerConfigLocation,
        customerPredefinedTemplateLocation,
        language,
        samplesCount: isSampleGeneration ? initialSamples : samplesCount,
        textGenSettings: {
          __typename: 'TextGenerationSettings',
          structuredTextGen: generationMethod === 'template',
        },
        voiceGenSettings: {
          __typename: 'VoiceGenerationSettings',
          sliceRatio: 1,
          noiseRatio: 0,
        },
      },
    });

    if (response) {
      onClose();
    } else {
      notifications.show({
        color: 'red',
        title: t('dataGenerationPage.generateModal.errorMessage'),
        icon: <IconAlertTriangle />,
        message: t('common.tryAgain'),
      });
    }
    setSubmitting(false);
  };
  const isSubmitDisabled = hasSettings && !samplesInputDisableOverride; // TODO not sure if that is the correct implementation, need to further investigate and open relevant bug (this was implemented a while ago)
  return (
    <ModalBase
      opened={opened}
      title={t('dataGenerationPage.generateModal.title', { modelType: t('common.modelType.NLP') })}
      onClose={onClose}
      onClick={(event) => {
        event.stopPropagation();
      }}
      data-testid={testIds.wrapper}
      {...modalBaseProps}
    >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack gap='lg'>
          <Stack gap={0}>
            <Input.Label required>{t('dataGenerationPage.generateModal.nlpConfiguration.title')}</Input.Label>
            <Input.Description my={4}>
              {t('dataGenerationPage.generateModal.nlpConfiguration.description')}
            </Input.Description>
            <SegmentedControl
              data={[
                {
                  value: 'internal',
                  label: t('dataGenerationPage.generateModal.nlpConfiguration.internal'),
                  disabled: true,
                },
                { value: 'external', label: t('dataGenerationPage.generateModal.nlpConfiguration.external') },
              ]}
              {...form.getInputProps('inputSource')}
              data-testid={testIds.inputSource}
            />
          </Stack>

          <TextInput
            label={t('dataGenerationPage.generateModal.customerConfigInput.title')}
            placeholder={t('dataGenerationPage.generateModal.customerConfigInput.placeholder')}
            {...form.getInputProps('customerConfigLocation')}
            data-testid={testIds.customerConfigLocation}
          />

          <TextInput
            label={t('dataGenerationPage.generateModal.customerConfigPromptsInput.title')}
            placeholder={t('dataGenerationPage.generateModal.customerConfigPromptsInput.placeholder')}
            {...form.getInputProps('customerConfigPromptsLocation')}
            data-testid={testIds.customerConfigPromptsLocation}
          />

          <TextInput
            label={t('dataGenerationPage.generateModal.predefinedTemplateInput.title')}
            placeholder={t('dataGenerationPage.generateModal.predefinedTemplateInput.placeholder')}
            {...form.getInputProps('customerPredefinedTemplateLocation')}
            data-testid={testIds.customerPredefinedTemplateLocation}
          />
          <Divider />

          <Stack gap='md' variant=''>
            <Radio.Group
              label={t('dataGenerationPage.generateModal.generationMethodInput.title')}
              required
              readOnly={hasSettings}
              {...form.getInputProps('generationMethod')}
              data-testid={testIds.generationMethod}
            >
              <Group>
                <Radio
                  value='template'
                  label={t('dataGenerationPage.generateModal.generationMethodInput.templateBased')}
                />
                <Radio value='llm' label={t('dataGenerationPage.generateModal.generationMethodInput.llmBased')} />
              </Group>
            </Radio.Group>
            <LanguageSelect
              initialValue='en-US'
              label={t('dataGenerationPage.generateModal.languageInput.title')}
              placeholder={t('dataGenerationPage.generateModal.languageInput.placeholder')}
              required
              {...form.getInputProps('language')}
            />
            <NumberInput
              label={t('dataGenerationPage.generateModal.initialSamplesInput.title')}
              description={t('dataGenerationPage.generateModal.initialSamplesInput.description')}
              required
              {...form.getInputProps('initialSamples', { withError: true })}
              data-testid={testIds.initialSamples}
            />
            <NumberInput
              label={t('dataGenerationPage.generateModal.numberOfSamplesInput.title')}
              description={t('dataGenerationPage.generateModal.numberOfSamplesInput.description')}
              required
              {...form.getInputProps('samplesCount', { withError: true })}
              {...(samplesInputDisableOverride ? { disabled: false } : {})}
              data-testid={testIds.samplesCount}
            />
          </Stack>

          <Group justify='end' pt='lg'>
            <Button variant='subtle' onClick={onClose} data-testid={testIds.cancelButton}>
              {t('common.cancel')}
            </Button>
            <Button
              variant='submit'
              loading={submitting}
              disabled={isSubmitDisabled}
              type='submit'
              data-testid={testIds.submitButton}
            >
              {t('dataGenerationPage.generateModal.submit')}
            </Button>
          </Group>
        </Stack>
      </form>
    </ModalBase>
  );
};
function deriveSamplesCount(settings: DataGenUISettings | undefined, cachedSamplesCount: number | null) {
  let samplesCount = SAMPLE_COUNTS.full;
  if (settings) {
    samplesCount = settings.samplesCount;
  } else if (cachedSamplesCount) {
    samplesCount = cachedSamplesCount;
  }
  return samplesCount;
}
